import * as THREE from 'three'

import { createRoot } from 'react-dom/client'
import React, { useCallback, useEffect, useRef, useMemo, Suspense, useState } from 'react'
import { Canvas, extend, useFrame, useThree } from '@react-three/fiber'
import lerp from 'lerp'
import { MeshLineGeometry, MeshLineMaterial, raycast } from 'meshline'
import { Stats, Text } from '@react-three/drei'

import { EffectComposer, Glitch } from '@react-three/postprocessing'
import { GlitchMode } from 'postprocessing'

import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass'
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass'
import { FilmPass } from 'three/examples/jsm/postprocessing/FilmPass'
import './styles.css'
import { AppBar, Box, Button, Card, CardActions, CardContent, CardMedia, Toolbar, Typography } from '@mui/material'

import { Textillate } from 'textillate-react'

import IconButton from '@mui/material/IconButton'

import MoreIcon from '@mui/icons-material/MoreVert'
import { Image } from '@mui/icons-material'

// Makes these prototypes available as "native" jsx-string elements
extend({ EffectComposer, ShaderPass, RenderPass, UnrealBloomPass, FilmPass })
extend({ MeshLineGeometry, MeshLineMaterial })

function Fatline({ curve, width, color, speed }) {
  const material = useRef()
  useFrame((_, delta) => (material.current.uniforms.dashOffset.value -= (delta * speed)))
  return (
    <mesh>
      <meshLineGeometry attach='geometry' points={curve} />
      <meshLineMaterial attach='material' ref={material} transparent={true} depthTest={false} lineWidth={width}
                        color={color} dashArray={0.1} dashRatio={0.95} />
    </mesh>
  )
}

function Effects() {
  return (
    <EffectComposer>
      <Glitch
        delay={[0.5, 1.5]}
        duration={[0.6, 1.0]}
        strength={[0.1, 0.2]}
        mode={GlitchMode.SPORADIC} // try CONSTANT_MILD
        active // toggle on/off
        ratio={0.1}
      />
    </EffectComposer>
  )
}

function r() {
  return Math.max(0.8, Math.random())
}


function Lines({ mouse, count, colors, radius = 10, rotate = 30 }) {
  const lines = useMemo(
    () =>
      new Array(count).fill().map(() => {
        const points = new Array(31).fill().map((_, index) => {
          let x = 0
          let y = 0
          if (index < 10) {
            const angle = 18 * index * (Math.PI / 180)
            x = Math.sin(angle) * radius * r() + 10
            y = Math.cos(angle) * radius * r()
          } else if (index < 15) {
            // draw a line
            x = ((index - 10) * -4 + 10) * r()
            y = -10 * r()
          } else if (index < 25) {
            const angle = (18 * (index - 15)) * (Math.PI / 180)
            x = Math.sin(angle + Math.PI) * radius * r() - 10
            y = Math.cos(angle + Math.PI) * radius * r()
          } else {
            // draw a line
            x = ((index - 25) * 4 - 10) * r()
            y = 10 * r()
          }

          const point = new THREE.Vector3(x, y, 0)
          // rorate the points
          const angle = rotate * (Math.PI / 180)
          point.applyAxisAngle(new THREE.Vector3(0, 0, 1), angle)
          return point
        })
        const curve = new THREE.CatmullRomCurve3(points).getPoints(1000)
        return {
          color: colors[parseInt(colors.length * Math.random())],
          width: 0.1,
          speed: Math.max(0.05, 0.1 * Math.random()),
          curve
        }
      }),
    [count]
  )


  return (
    <group position={[0, 0, 0]}>
      {lines.map((props, index) => (
        <Fatline key={index} {...props} />
      ))}
    </group>
  )
}

function MousePos({ children, mouse }) {

  const ref = useRef()
  const { size, viewport } = useThree()
  const aspect = size.width / viewport.width
  useFrame((state, delta) => {
    if (ref.current) {
      ref.current.rotation.x = lerp(ref.current.rotation.x, mouse.current[1] / aspect / 50, 0.3)
      ref.current.rotation.y = lerp(ref.current.rotation.y, mouse.current[0] / aspect / 100, 0.3)
    }
  })

  return (
    <group ref={ref} position={[0, 0, 0]}>
      {children}
    </group>
  )
}


function BackgroundAnimation({ mouse }) {
  // const [hovered, hover] = useState(false)
  // const [down, set] = useState(false)

  // const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
  const blueColors = [
    '#0000FF', // Bright Blue
    '#add8e6', // Light Blue
    '#007BB7', // French Blue
    '#4169E1', // Royal Blue
    '#4682B4', // Steel Blue
    '#0D98BA', // Blue Green
    '#1D2951', // Space Cadet
    '#00008B', // Dark Blue
    '#87CEFA', // Light Sky Blue
    '#7B68EE'  // Medium Slate Blue
  ]
  const redColors = [
    '#FF0000', // Bright Red
    '#FF6347', // Tomato
    '#DC143C', // Crimson
    '#B22222', // Firebrick
    '#FF4500', // Orange Red
    '#FF6347', // Tomato
    '#FF7F50', // Coral
    '#FA8072', // Salmon
    '#E9967A', // Dark Salmon
    '#FFA07A'  // Light Salmon
  ]

  const pinkColors = [
    '#FFC0CB', // Pink
    '#FFB6C1', // Light Pink
    '#FF69B4', // Hot Pink
    '#FF1493', // Deep Pink
    '#C71585', // Medium Violet Red
    '#DB7093', // Pale Violet Red
    '#E75480', // Dark Pink
    '#FF77FF', // Bright Pink
    '#E4717A', // Cabaret
    '#FF007F'  // Rose
  ]

  const [down, set] = useState(false)

  return (
    <>
      <Canvas
        style={{ position: 'fixed', top: 0, left: 0, zIndex: -1 }}
        camera={{ fov: 100, position: [0, 0, 30] }}
        onMouseUp={() => set(false)}
        onMouseDown={() => set(true)}
        onCreated={({ gl }) => {
          gl.toneMapping = THREE.LinearToneMapping
          gl.setClearColor(new THREE.Color('#020207'))
        }}>
        <MousePos mouse={mouse}>
          <Lines count={30} colors={blueColors} />
          <Lines count={30} colors={[...redColors, ...pinkColors]} rotate={-30} />
        </MousePos>
        {/*<Effects />*/}
        {/*<Stats />*/}
      </Canvas>
    </>
  )
}

// ReactDOM.render(<App />, document.getElementById('root'))
function AppWithCallbackAfterRender() {
  const mouse = useRef([0, 0])
  const onMouseMove = useCallback(({
                                     clientX: x,
                                     clientY: y
                                   }) => (mouse.current = [x - window.innerWidth / 2, y - window.innerHeight / 2]), [])

  useEffect(() => {
    console.log('rendered')
  })

  return (
    <div onMouseMove={onMouseMove}>
      <BackgroundAnimation mouse={mouse} />
      <div className={'content'}>
        <AppBar position='static' sx={{ backgroundColor: 'rgba(0,0,0,0.8)' }} position={'fixed'}>
          <Toolbar>
            <img src={'/Logo.png'} style={{ width: 'auto', height: '30px' }} />
            {/*<Typography variant='h6' color='inherit' component='div'>*/}
            {/*  WeaverseLab*/}
            {/*</Typography>*/}

            <Button size='small' variant={'outlined'} sx={{ marginLeft: 'auto', borderRadius: '30px' }}
                    onClick={() => {
                      window.open('https://forms.gle/TLqMrjfZzEDeFVZbA', '_blank')
                    }}
            >
              お問い合わせ
            </Button>
          </Toolbar>
        </AppBar>
        <div className={'top'}>
          <div>
            <p className={'top-title'}>Weaverse</p>
            <p>Weave your <br />
              Universe & Metaverse</p>
          </div>
        </div>
        <div className={'fullpage bg-wedo'}>
          <div className={'wedo'}>
            <div>
              <p className={'content-title'}>We Do</p>
              <p>
                貴社の挑戦にご協力します。</p>
              <p className={'content-text'}>
                XR技術による先進的なソリューションを求めている企業様、スピーディーな提案・実装を求めている企業様、まずはお気軽にご相談ください。
              </p>
            </div>
            <div className={'wedo-right'}>
              <img
                src={'/wedo.jpg'} />
            </div>
          </div>
        </div>
        <div className={'fullpage bg-wecan'}>
          <div className={'wecan'}>
            <div>
              <img alt={"We Can"}
                src={'/wecan.jpg'} />
            </div>
            <div>
              <p className={'content-title'}>Our Strengths</p>
              <p className={'content-text'}>弊社のチームは、メタバースに精通し、卓越したスキルを持った専門家たちで構成されています。<br />
                メタバースの住人でしか知り得ないノウハウを余すことなく盛り込んだ、本当に意味のあるXRソリューションを提供します。
              </p>
            </div>
          </div>
        </div>
        <div className={''}>
          <div className={'member'}>
            <div className={'member-title'}>
              <div className={'content-title'}>
                役員紹介
              </div>
            </div>
            <div className={'member-cards'}>
              <div className={'member-card'}>
                <img src={'/njelly.jpg'} />
                <div className={'member-name'}>N-JELLY</div>
                <div className={'member-role'}>CEO</div>
              </div>
              <div className={'member-card'}>
                <img src={'/rabbuttz.jpg'} />
                <div className={'member-name'}>あむ(Rabbuttz)</div>
                <div className={'member-role'}>COO</div>
              </div>
              <div className={'member-card'}>
                <img src={'/orange.jpg'} />
                <div className={'member-name'}>オレンジ</div>
                <div className={'member-role'}>CMO</div>
              </div>
              <div className={'member-card'}>
                <img src={'/kokoa.jpg'} />
                <div className={'member-name'}>kokoa</div>
                <div className={'member-role'}>CTO</div>
              </div>
            </div>
          </div>
        </div>
        <div className={'fullpage bg-about'}>
          <div className={'about'}>
            <div>
              <h2>会社概要</h2>
              <p>社名： WeaverseLab株式会社</p>
              <p>設立： 2023年10月16日</p>
              <p>資本金： 100万円</p>
              <p>事業内容： XRコンテンツ企画 XRコンテンツ開発</p>
            </div>
            <div className={'contact'}>
              <div>
                <p className={'content-subtitle pb-2'}>お気軽にお問い合わせください。</p>
                <Button variant={'outlined'} color={'info'} onClick={() => {
                  window.open('https://forms.gle/TLqMrjfZzEDeFVZbA', '_blank')
                }}>お問い合わせはこちら</Button>
              </div>
            </div>
          </div>
        </div>
        {/*<div className={'contact'}>*/}
        {/*</div>*/}
      </div>
      <div className={'footer'}>
        <p>© 2023 WeaverseLab株式会社</p>
      </div>
    </div>
  )
}

const container = document.getElementById('root')
const root = createRoot(container)
root.render(<AppWithCallbackAfterRender />)
